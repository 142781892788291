import {Descriptions, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import global from "../../global";
import {QRCodeCanvas} from 'qrcode.react';
import {useEffect, useState} from "react";

const ExamApplyInfo = () => {
  const examApply = useSelector((state: any) => state.examApply)
  const [info, setInfo] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    let id = examApply.info.id;
    if (id > 0) {
      dispatch.examApply.info({id: examApply.info.id}).then((res: any) => {
        setInfo(res.data);
      });
    }

  }, [dispatch.examApply, examApply.info, examApply.info.id]);
  return (
    <>
      <Drawer
        title="查看报名详情"
        width={500}
        open={examApply.openInfo}
        onClose={() => dispatch.examApply.setData({openInfo: false})}
        bodyStyle={{paddingBottom: 80}}
      >

        <Descriptions title="" column={1}>
          <Descriptions.Item label={"录入成绩二维码"}>
            <QRCodeCanvas
              value={'https://wemp.taoyun.art/examQrCode?applyId=' + info?.id + '&idCardNumber=' + info?.idCardNumber}  //生成二维码的内容
              size={128} //二维码尺寸
              fgColor="#000000"  //二维码颜色
            />
          </Descriptions.Item>
          <Descriptions.Item label="编号">{info?.id}</Descriptions.Item>
          <Descriptions.Item label="考点">{info?.companyName}</Descriptions.Item>
          <Descriptions.Item label="专业">{info?.specialityName}</Descriptions.Item>
          <Descriptions.Item label="等级">{info?.level}</Descriptions.Item>
          <Descriptions.Item label="考试日期">{info?.auditDate}</Descriptions.Item>
          <Descriptions.Item label="姓名">{info?.idCardName}</Descriptions.Item>
          <Descriptions.Item label="姓名拼音">{info?.namePinyin}</Descriptions.Item>
          <Descriptions.Item label="证件类型">{info?.idCardType}</Descriptions.Item>
          <Descriptions.Item label="证件号码">{info?.idCardNumber}</Descriptions.Item>
          <Descriptions.Item label="出生日期">{info?.birthday}</Descriptions.Item>
          <Descriptions.Item label="性别">{info?.sex}</Descriptions.Item>
          <Descriptions.Item label="国籍">{info?.country}</Descriptions.Item>
          <Descriptions.Item label="民族">{info?.nation}</Descriptions.Item>
          <Descriptions.Item label="地区">{info?.area}</Descriptions.Item>
          <Descriptions.Item label="联系地址">{info?.address}</Descriptions.Item>
          <Descriptions.Item label="照片">
            {
              info?.photoFile &&
              <img src={global.getFileOpenUrl(info?.photoFile)} alt="" style={{width: 150}}/>
            }
          </Descriptions.Item>
          <Descriptions.Item label="机构名称">{info?.orgName}</Descriptions.Item>
          <Descriptions.Item label="指导老师">{info?.teacher}</Descriptions.Item>
          <Descriptions.Item label="老师电话">{info?.teacherMobile}</Descriptions.Item>
          <Descriptions.Item label="家长姓名">{info?.parents}</Descriptions.Item>
          <Descriptions.Item label="家长电话">{info?.mobile}</Descriptions.Item>
          <Descriptions.Item label="报名状态">{info?.applyStatusName}</Descriptions.Item>
          <Descriptions.Item label="信息来源">{info?.sourceTypeName}</Descriptions.Item>
          <Descriptions.Item label="备注">{info?.memo}</Descriptions.Item>
          <Descriptions.Item label="报名时间">{info?.created}</Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  )
}

export default ExamApplyInfo